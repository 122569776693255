<template>
  <div>
    <b-title>
      <id-icon class="icon" />
      {{ $t("submission.title") }}
    </b-title>
    <b-information>
      <div v-html="$t('submission.information')" />
    </b-information>
    <b-container center>
      <form @submit.prevent="onSubmit" class="d-center w-full">
        <b-alert class="m-b-20" v-if="showAlert" />
        <b-form-row
          :label="$t('submission.last_name')"
          :validation="v$.lastname"
          display="center"
        >
          <b-input v-model.trim="v$.lastname.$model" />
        </b-form-row>
        <b-form-row
          :label="$t('submission.broker')"
          :validation="v$.broker"
          :help="$t('submission.broker_placeholder')"
          display="center"
        >
          <b-input
            v-model.trim="v$.broker.$model"
            :disabled="disabledBroker ? 'disabled' : false"
          />
        </b-form-row>
        <b-form-row
          :label="$t('submission.email')"
          :validation="v$.email"
          display="center"
        >
          <b-input v-model.trim="v$.email.$model" type="email" />
        </b-form-row>
        <b-form-row>
          <b-form-upload
            v-model="v$.files.$model"
            :validation="v$.files"
            name="bia"
          >
            <template #placeholder>
              <span v-html="$t('submission.drag_drop_files')"></span>
            </template>
          </b-form-upload>
        </b-form-row>
        <b-btn type="submit" :loading="isLoading">
          {{ $t("submission.submit") }}
        </b-btn>
      </form>
    </b-container>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import useStep1Form from "@/forms/useStep1Form";
import IdIcon from "@/components/icon/IdIcon.vue";
import formHttp from "@/http/formHttp";
import { useSubscriber } from "@/use/useSubscriber";
import { useRouter } from "vue-router";
import { useLoader } from "@/use/useLoader";

export default defineComponent({
  components: { IdIcon },
  setup() {
    const router = useRouter();
    const subscriber = useSubscriber();
    const { v$, fields } = useStep1Form();
    const disabledBroker = ref<boolean>(false);
    const showAlert = ref<boolean>(false);
    const { isLoading, addLoading, removeLoading } = useLoader();

    onMounted(async () => {
      try {
        const { data } = await formHttp.getStep1();
        Object.assign(fields, data.content);

        if (subscriber.getBroker()) {
          fields.broker = subscriber.getBroker() as string;
          disabledBroker.value = true;
        }
      } catch ({ response }) {
        showAlert.value = true;
      }
    });

    const onSubmit = async () => {
      v$.value.$touch();

      if (v$.value.$invalid) return;

      try {
        addLoading();
        await formHttp.postStep1(fields);
        await subscriber.refreshCurrentStep();
        router.push({ name: "FormMedicalQuestionnaire" });
      } catch ({ response }) {
        showAlert.value = true;
      }

      removeLoading();
    };

    return { v$, fields, onSubmit, showAlert, disabledBroker, isLoading };
  },
});
</script>
