
import { defineComponent, onMounted, ref } from "vue";
import useStep1Form from "@/forms/useStep1Form";
import IdIcon from "@/components/icon/IdIcon.vue";
import formHttp from "@/http/formHttp";
import { useSubscriber } from "@/use/useSubscriber";
import { useRouter } from "vue-router";
import { useLoader } from "@/use/useLoader";

export default defineComponent({
  components: { IdIcon },
  setup() {
    const router = useRouter();
    const subscriber = useSubscriber();
    const { v$, fields } = useStep1Form();
    const disabledBroker = ref<boolean>(false);
    const showAlert = ref<boolean>(false);
    const { isLoading, addLoading, removeLoading } = useLoader();

    onMounted(async () => {
      try {
        const { data } = await formHttp.getStep1();
        Object.assign(fields, data.content);

        if (subscriber.getBroker()) {
          fields.broker = subscriber.getBroker() as string;
          disabledBroker.value = true;
        }
      } catch ({ response }) {
        showAlert.value = true;
      }
    });

    const onSubmit = async () => {
      v$.value.$touch();

      if (v$.value.$invalid) return;

      try {
        addLoading();
        await formHttp.postStep1(fields);
        await subscriber.refreshCurrentStep();
        router.push({ name: "FormMedicalQuestionnaire" });
      } catch ({ response }) {
        showAlert.value = true;
      }

      removeLoading();
    };

    return { v$, fields, onSubmit, showAlert, disabledBroker, isLoading };
  },
});
