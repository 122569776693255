import { reactive, Ref } from "vue";
import useVuelidate, { Validation, ValidationArgs } from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  email,
  helpers,
} from "@vuelidate/validators";

export interface Step1FormInterface {
  lastname: string;
  broker: string;
  email: string;
  files: string[];
}

export interface FormInterface {
  v$: Ref<Validation<ValidationArgs>>;
  fields: Step1FormInterface;
}

const form = (): FormInterface => {
  const state = reactive<Step1FormInterface>({
    lastname: "",
    broker: "",
    email: "",
    files: [],
  });

  const rules: ValidationArgs = {
    lastname: {
      required: helpers.withMessage("required", required),
      minLength: helpers.withMessage("min_length", minLength(2)),
      maxLength: helpers.withMessage("max_length", maxLength(50)),
      /** Regex: Uniquement lettre, espace et tiret */
      alpha: helpers.withMessage("alpha", helpers.regex(/^[a-zA-ZÀ-ž\-\s]+$/i)),
    },
    broker: {
      minLength: helpers.withMessage("min_length", minLength(2)),
      maxLength: helpers.withMessage("max_length", maxLength(50)),
      /** Regex: Uniquement lettre, espace et tiret */
      alpha: helpers.withMessage("alpha", helpers.regex(/^[a-zA-ZÀ-ž\-\s]+$/i)),
    },
    email: {
      required: helpers.withMessage("required", required),
      email: helpers.withMessage("email", email),
    },
    files: {
      required: helpers.withMessage("required", required),
    },
  };

  const v$ = useVuelidate(rules, state);

  return { v$, fields: state };
};

export default form;
